import { template as template_09a51bfa4c72403f81addda83b844845 } from "@ember/template-compiler";
const FKText = template_09a51bfa4c72403f81addda83b844845(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
