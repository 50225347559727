import { template as template_db6ec2dd503d495c8a2a424daf6e6725 } from "@ember/template-compiler";
const FKControlMenuContainer = template_db6ec2dd503d495c8a2a424daf6e6725(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
